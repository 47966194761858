<template>
  <div class="sidebar-cart">
    <div class="sidebar-cart-wrapper">
      <div class="sidebar-cart__top-line">
        <img
          class="sidebar-cart__top-line-back"
          src="@/assets/icons/arrow-left.svg"
          @click="$emit('toggleLayers', ['orderHistory'])"
        />
        <span class="sidebar-cart__top-line-title">ИСТОРИЯ ЗАКАЗОВ</span>
        <div
          id="mobile-burger"
          @click="$emit('toggleLayers', ['orderHistory'])"
          class="mobile-burger change"
        >
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </div>
      <div v-if="isLoading" class="sidebar-cart__middle loader-wrapper">
        <circles-to-rhombuses-spinner
          :animation-duration="1200"
          :circles-num="3"
          :circle-size="15"
          color="#585854"
        />
      </div>
      <div v-else class="sidebar-cart__middle orders-list">
        <div v-for="(order, i) in orders" :key="i" class="orders-item">
          <h3 class="orders-item__date">
            {{ order.localDate }}
          </h3>
          <span class="orders-item__line"></span>
          <div v-if="order.receiveType === 'delivery'">
            <div class="orders-item__variant">
              {{ order.receiveTypeName }}
            </div>
            <div class="orders-item__adress">
              {{ order.address.street + "., д." + order.address.house }}
            </div>
          </div>
          <div v-else>
            <div class="orders-item__variant">
              {{ order.receiveTypeName }}
            </div>
            <div class="orders-item__adress">
              {{ order.restaurantAddress }}
            </div>
          </div>

          <span class="orders-item__line"></span>
          <div class="orders-item__header">
            <div class="orders-item__header--title">Ваш заказ:</div>
            <div
              @click="toggleOrder(order)"
              v-if="!order.isOpened"
              class="orders-item__header--value red"
            >
              Развернуть <img src="@/assets/icons/expand.svg" />
            </div>
            <div
              @click="toggleOrder(order)"
              v-if="order.isOpened"
              class="orders-item__header--value"
            >
              Свернуть <img src="@/assets/icons/collapse.svg" />
            </div>
          </div>
          <transition name="fade">
            <div v-if="order.isOpened">
              <template v-for="(item, i) in order.content">
                <div :key="i" class="orders-menu" v-if="item.position">
                  <div class="orders-menu__dish">
                    <div class="orders-menu__dish--name">
                      {{ item.position.name }}
                    </div>
                    <div>{{ item.positionOption.name }}</div>
                    <div>
                      Порция {{ item.positionOptionServing.quantity }}
                      {{ item.positionOptionServing.measure }}
                    </div>
                  </div>
                  <div class="orders-menu__value">
                    {{ item.number }} x
                    <span class="orders-menu__value--price"
                      >{{ item.price }} ₽</span
                    >
                  </div>
                </div>
              </template>
            </div>
          </transition>
          <span class="orders-item__line"></span>
          <div class="orders-item__amount">
            <div class="orders-item__sum">
              <div class="orders-item__sum--title">Сумма заказа:</div>
              <div class="orders-item__sum--value">{{ order.totalCost }} ₽</div>
            </div>
            <div class="orders-item__sum">
              <div class="orders-item__sum--title">Доставка:</div>
              <div class="orders-item__sum--value">
                {{ order.deliveryCost }} ₽
              </div>
            </div>
            <div class="orders-item__sum">
              <div class="orders-item__sum--title">Итого:</div>
              <div class="orders-item__sum--value">
                {{ order.deliveryCost + order.totalCost }} ₽
              </div>
            </div>
            <div class="orders-item__bonus">
              <div class="orders-item__bonus--title">Получено бонусов:</div>
              <div class="orders-item__bonus--value">
                + {{ order.accountRefill }} ₽
              </div>
            </div>
          </div>
          <div
            v-if="order.receiveType !== 'restaurant'"
            class="orders-item__bottom"
          >
            <FormSubmitButton
              class="button button-no-background"
              @click.native="kek(order.content)"
              :isLoading="isLoading"
              :text="'ПОВТОРИТЬ ЗАКАЗ'"
            />
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Modal
        v-if="showModal"
        :data="modalData"
        @close="showModal = false"
        @callback="addOrderToCart(currentOrder)"
      />
      <Modal
        v-if="showModalWrong"
        :data="modalDataWrong"
        @close="showModalWrong = false"
        @callback="closeWrongModal"
      />
    </transition>
  </div>
</template>

<script>
import { DataFactory } from "@/services/Api/DataFactory.js";
const users = DataFactory.get("users");
const dicts = DataFactory.get("dicts");
// const positions = DataFactory.get("positions");

import { CirclesToRhombusesSpinner } from "epic-spinners";
import FormSubmitButton from "@/components/FormSubmitButton.vue";
import Modal from "@/components/ModalWithoutStore.vue";
export default {
  components: {
    CirclesToRhombusesSpinner,
    FormSubmitButton,
    Modal,
  },
  data() {
    return {
      orders: null,
      measures: null,
      isLoading: false,
      orderIsOpen: false,
      showModal: false,
      showModalWrong: false,
      modalData: null,
      modalDataWrong: null,
      currentOrder: null,
    };
  },
  computed: {
    positionOptionsDisableSwitcher() {
      let state = this.$store.state;
      let result =
        (state.orderInfo.receiveType === "delivery" &&
          state.deliveryZone === null) ||
        (state.orderInfo.receiveType === "pick-up" &&
          state.choosenRestaurant === null) ||
        (state.orderInfo.receiveType === "restaurant" &&
          state.orderInfo.tableId === null);
      return result;
    },
  },
  created() {
    this.getOrdersHistory();
  },
  methods: {
    checkDeliveryZone() {
      if (this.positionOptionsDisableSwitcher) {
        let getSwitch =
          this.$store.state.orderInfo.receiveType === "delivery"
            ? {
                switch: "chooseAddress",
                text: "Для добавления в корзину вам необходимо ввести адрес доставки",
              }
            : {
                switch: "chooseRestaurant",
                text: "Для добавления в корзину вам необходимо выбрать ресторан",
              };
        let modalData = {
          variable: "modalData",
          data: {
            description: getSwitch.text,
            buttonText: "ВВЕСТИ АДРЕС",
            switches: ["modalSwitch", getSwitch.switch],
          },
        };
        this.$store.commit("changeStateVariable", modalData);
        //this.$emit("toggleLayers", ["modalSwitch"]);
        return false;
      }
      return true;
    },
    kek(item) {
      if (this.checkDeliveryZone()) {
        if (this.$store.state.cart.length !== 0) {
          this.currentOrder = item;
          this.modalData = {
            description: "Ваша корзина будет очищена, для повторного заказа.",
            buttonText: "ПЕРЕЙТИ К ЗАКАЗУ",
            callback: true,
          };
          this.showModal = true;
        } else {
          this.addOrderToCart(item);
        }
      }
    },
    async addOrderToCart(item) {
      this.$store.commit("clearCart");
      let wrongItems = [];
      item.forEach(async (content) => {
        if (
          content.position.receiveTypes.includes(
            this.$store.state.orderInfo.receiveType
          )
        ) {
          if (
            (this.$store.state.orderInfo.receiveType === "delivery" &&
              content.positionOption.deliveryZoneTypes.some((item) =>
                this.$store.state.deliveryZone.deliveryZoneTypes.includes(item)
              )) ||
            this.$store.state.orderInfo.receiveType !== "delivery"
          ) {
            let options = [
              {
                ...content.positionOption,
                servings: [
                  {
                    ...content.positionOptionServing,
                    selected: true,
                    counter: content.number,
                    price: content.price,
                  },
                ],
                counter: content.number,
                group_name: content.position.name,
                selectedPrice: content.price,
                coast: content.price,
              },
            ];
            /* let { data } = await positions.getPosition(content.position.id);
            let positionFromApi = data;
            console.log(positionFromApi);
            positionFromApi.options.forEach((x) => {
              if (x.id === content.positionOption.id) {
                x.counter = content.number;
                x.group_name = content.position.name;
                x.selectedPrice = content.price;
                x.coast = content.cost;
                x.servings.forEach((y) => {
                  if (y.id === content.positionOptionServing.id) {
                    y.selected = true;
                  }
                });
              }
            }); */
            let position = { ...content.position, options: options };
            this.$store.commit("addToCart", position);
          } else {
            wrongItems.push(content);
          }
        } else {
          wrongItems.push(content);
        }
      });
      this.showModal = false;
      if (wrongItems.length > 0) {
        console.log(wrongItems);
        let description =
          "К сожалению следующие товары нельзя заказать при выбраном способе получения: \n";
        wrongItems.forEach((x, index) => {
          description =
            description +
            `${index + 1}) ${x.position.name} - ${x.positionOption.name}\n`;
        });
        this.modalDataWrong = {
          description: description,
          buttonText: "OK",
          callback: true,
        };
        this.showModalWrong = true;
      } else {
        this.$emit("toggleLayers", ["cart"]);
      }
    },
    closeWrongModal() {
      this.showModalWrong = false;
      this.$emit("toggleLayers", ["cart"]);
    },
    toggleOrder(order) {
      order.isOpened = !order.isOpened;
      this.$forceUpdate();
    },
    async getOrdersHistory() {
      this.isLoading = true;
      const measuresAnswer = await dicts.getMeasures();
      this.measures = measuresAnswer.data;
      const { data } = await users.getUsersOrdersHistory();
      this.orders = data;
      this.orders.forEach((order) => {
        const orderDate = new Date(order.receiveTime).toLocaleString([], {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        const orderTime = new Date(order.receiveTime).toLocaleString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        order.localDate = `${orderDate} в ${orderTime}`;
        order.isOpened = false;
        order.receiveTypeName = this.$store.state.receiveTypesFull.find(
          (x) => x.id === order.receiveType
        ).name;
        order.content.forEach((x) => {
          if (x.positionOptionServing)
            x.positionOptionServing.measure = this.measures.find(
              (l) => l.id === x.positionOptionServing.measureId
            ).shortName;
        });
      });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.sidebar-cart-wrapper {
  justify-content: flex-start;
}
.button {
  width: 80%;
}
.orders {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 100%;
    @media (max-width: $viewport--md) {
      gap: 14px;
    }
  }
  &-item {
    background: rgba(255, 255, 255, 0.41);
    color: #585854;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 12px;
    border: 1px solid rgba(88, 88, 84, 0.27);
    box-shadow: 0px 1px 34px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    @media (max-width: $viewport--md) {
      font-size: 12px;
      line-height: 14px;
    }
    &__bottom {
      display: flex;
      justify-content: center;
    }
    &__line {
      display: block;
      background: #c7c4bd;
      height: 1px;
    }
    &__date {
      font-family: "Open Sans";
      font-weight: 500;
      color: #424242;
      margin: 0;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 12px;
      @media (max-width: $viewport--md) {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 10px;
      }
    }
    &__variant {
      color: #868683;
      font-weight: 500;
      margin-top: 12px;
      @media (max-width: $viewport--md) {
        font-size: 10px;
        line-height: 12px;
        margin-top: 10px;
      }
    }
    &__adress {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin: 6px 0 12px;
      @media (max-width: $viewport--md) {
        font-size: 14px;
        line-height: 17px;
        margin: 10px 0 10px;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 12px 0;
      cursor: pointer;
      &--value {
        display: flex;
        gap: 6px;
        align-items: center;
        &.red {
          color: #83001b;
        }
      }
    }
    &__amount {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: 12px;
      margin-bottom: 20px;
      @media (max-width: $viewport--md) {
        margin-bottom: 10px;
      }
    }
    &__sum {
      display: flex;
      justify-content: space-between;
      &--value {
        color: #424242;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        @media (max-width: $viewport--md) {
          font-size: 15px;
        }
      }
    }
    &__bonus {
      display: flex;
      justify-content: space-between;
      &--title {
        font-weight: 300;
        font-style: italic;
      }
      &--value {
        color: #83001b;
        font-style: italic;
        font-weight: 300;
      }
    }
  }
  &-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    @media (max-width: $viewport--md) {
      margin-bottom: 12px;
    }
    &__dish {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #7e8389;
      &--name {
        font-family: "Open Sans";
        font-weight: 500;
        color: #424242;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
      }
    }
    &__value {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      width: 30%;
      text-align: end;
      &--price {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.sidebar-cart__middle > * {
  margin-bottom: 0;
}
</style>
