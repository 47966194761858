var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'vdatetime-time-picker': true,
    'vdatetime-time-picker__with-suffix': _vm.use12Hour,
  }},[_c('div',{ref:"hourList",staticClass:"vdatetime-time-picker__list vdatetime-time-picker__list--hours"},_vm._l((_vm.hours),function(hour){return _c('div',{key:hour.number,staticClass:"vdatetime-time-picker__item",class:{
        'vdatetime-time-picker__item--selected': hour.selected,
        'vdatetime-time-picker__item--disabled': hour.disabled,
      },on:{"click":function($event){return _vm.selectHour(hour)}}},[_vm._v(" "+_vm._s(_vm.formatHour(hour.number))+" ")])}),0),_c('div',{ref:"minuteList",staticClass:"vdatetime-time-picker__list vdatetime-time-picker__list--minutes"},_vm._l((_vm.minutes),function(minute){return _c('div',{key:minute.number,staticClass:"vdatetime-time-picker__item",class:{
        'vdatetime-time-picker__item--selected': minute.selected,
        'vdatetime-time-picker__item--disabled': minute.disabled,
      },on:{"click":function($event){return _vm.selectMinute(minute)}}},[_vm._v(" "+_vm._s(minute.number)+" ")])}),0),(_vm.use12Hour)?_c('div',{ref:"suffixList",staticClass:"vdatetime-time-picker__list vdatetime-time-picker__list--suffix"},[_c('div',{staticClass:"vdatetime-time-picker__item",class:{ 'vdatetime-time-picker__item--selected': _vm.hour < 12 },on:{"click":function($event){return _vm.selectSuffix('am')}}},[_vm._v(" am ")]),_c('div',{staticClass:"vdatetime-time-picker__item",class:{ 'vdatetime-time-picker__item--selected': _vm.hour >= 12 },on:{"click":function($event){return _vm.selectSuffix('pm')}}},[_vm._v(" pm ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }