<template>
  <div class="sidebar-cart">
    <div class="sidebar-cart-wrapper">
      <div class="sidebar-cart__top-line">
        <img
          class="sidebar-cart__top-line-back"
          src="@/assets/icons/arrow-left.svg"
          @click="$emit('toggleLayers', ['signIn'])"
        />
        <span class="sidebar-cart__top-line-title">АВТОРИЗАЦИЯ</span>
        <div
          id="mobile-burger"
          @click="$emit('toggleLayers', ['signIn'])"
          class="mobile-burger change"
        >
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </div>
      <form
        id="signInForm"
        @submit.prevent="signIn()"
        class="sidebar-cart__middle"
      >
        <div class="full-line">
          <label class="input-label" for="phoneNumber"
            >Ваш номер телефона</label
          >
          <TheMask
            v-model="user.username"
            :mask="['+7 (9##) ###-##-##']"
            :masked="false"
            placeholder="+7 (9__) ___-__-__"
            type="tel"
            id="phoneNumber"
            class="input"
            required
            :class="{
              'error-input': userError.usernameLength || userError.userNotFound,
            }"
          />
          <span class="error-span" v-if="userError.usernameLength"
            >Номер введен не полностью</span
          >
          <span class="error-span" v-if="userError.userNotFound"
            >Пользователь не найден</span
          >
        </div>
        <div class="full-line">
          <label class="input-label" for="password">Ваш пароль</label>
          <input
            v-model="user.password"
            class="input"
            type="password"
            autocomplete="new-password"
            id="password"
            required
            :class="{ 'error-input': userError.password }"
          />
          <span class="error-span" v-if="userError.password"
            >Неверный номер или пароль</span
          >
        </div>
        <div class="full-line">
          <FormSubmitButton
            class="button-red"
            form="signInForm"
            :isLoading="isLoading"
            :text="'ВОЙТИ'"
          />
          <vue-telegram-login
            class="button-tg-login"
            mode="callback"
            :telegram-login="botName"
            @callback="yourCallbackFunction"
          />
          <div class="auth__links">
            <span
              @click.prevent="$emit('toggleLayers', ['recovery'])"
              class="auth__links--left"
              >Забыл пароль</span
            >
            <span
              @click.prevent="$emit('toggleLayers', ['signUpTg'])"
              class="auth__links--right"
              >Зарегистрироваться</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import auth from "@/services/Auth/AuthWorker.js";
import { DataFactory } from "@/services/Api/DataFactory.js";
const users = DataFactory.get("users");
import FormSubmitButton from "@/components/FormSubmitButton.vue";
import { vueTelegramLogin } from "vue-telegram-login";
export default {
  components: {
    TheMask,
    FormSubmitButton,
    vueTelegramLogin,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      userError: {
        usernameLength: false,
        userNotFound: false,
        password: false,
      },
      isLoading: false,
      botName: process.env.VUE_APP_TG_BOT_NAME,
    };
  },
  methods: {
    async yourCallbackFunction(data) {
      let objJsonStr = JSON.stringify(data);
      let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
      localStorage.setItem("auth_token", `Bearer ${objJsonB64} telegram`);
      const userOwnResponse = await users.getUserOwn();
      if (userOwnResponse.status === 200) {
        this.$store.commit("changeStateVariable", {
          variable: "userData",
          data: userOwnResponse.data,
        });
        this.$emit("toggleLayers", ["signIn", "account"]);
      } else alert("Ошибка!\nПользователь не найден");
    },
    async signIn() {
      if (this.isLoading) return;
      this.userError.usernameLength = false;
      let user = JSON.parse(JSON.stringify(this.user));
      if (user.username.length !== 9) {
        this.userError.usernameLength = true;
        return;
      }
      user.username = `+79${user.username}`;
      user.phone = user.username;
      let answer = await auth.signIn(user);
      if (answer === "passwordError") {
        this.userError.password = true;
        return;
      }
      if (answer === "userNotFound") {
        this.userError.userNotFound = true;
        return;
      }
      this.isLoading = true;
      //отсюда в тг
      const userOwnResponse = await users.getUserOwn();
      if (userOwnResponse === "continueSignUp") {
        this.$store.commit("changeStateVariable", {
          variable: "newUserData",
          data: user,
        });
        this.$emit("toggleLayers", ["signIn", "signUpContinue"]);
        return;
      } else {
        this.$store.commit("changeStateVariable", {
          variable: "userData",
          data: userOwnResponse.data,
        });
        this.$emit("toggleLayers", ["signIn", "account"]);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.button-tg-login {
  margin-top: 10px;
  .iframe {
    width: 100% !important;
  }
}
.tgme_widget_login_user_photo {
  display: none;
}
.tgme_widget.body_widget_login,
.tgme_widget_login_button {
  width: 100%;
}
</style>
