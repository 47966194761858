var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-wrapper"},[_c('ul',{staticClass:"footer__menu"},[_c('router-link',{attrs:{"to":{
          name: 'Menu',
          params: {
            groupId: 1,
          },
        },"tag":"li"}},[_vm._v("Меню")]),_c('router-link',{attrs:{"to":{
          name: 'howTo',
          params: {
            groupId: 1,
          },
        },"tag":"li"}},[_vm._v("Как приготовить?")]),_c('router-link',{attrs:{"to":{
          name: 'PaymentInfo',
        },"tag":"li"}},[_vm._v("Условия доставки")]),_c('router-link',{attrs:{"to":{
          name: 'Loyalty',
        },"tag":"li"}},[_vm._v("Бонусная программа")]),_c('router-link',{attrs:{"to":{
          name: 'Franchise',
        },"tag":"li"}},[_vm._v("Франшиза")]),_c('router-link',{attrs:{"to":{
          name: 'Requisites',
        },"tag":"li"}},[_vm._v("Реквизиты")]),_c('router-link',{attrs:{"to":{
          name: 'Privacy',
        },"tag":"li"}},[_vm._v("Политика конфиденциальности")])],1),_c('a',{staticClass:"footer__link",attrs:{"href":"tel:+78126007929"}},[_vm._v(" +7 (812) 600-79-29")]),_c('a',{staticClass:"footer__link",attrs:{"href":"mailto:zakaz@pelmenya-spb.ru"}},[_vm._v(" zakaz@pelmenya-spb.ru")]),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer__social"},[_c('a',{attrs:{"href":"https://vk.com/pelmenya.rest/","target":"_blank"}},[_c('img',{attrs:{"alt":"logo","src":require("@/assets/vk.svg")}})])])}]

export { render, staticRenderFns }