var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header",on:{"wheel":function($event){$event.preventDefault();},"scroll":function($event){$event.preventDefault();},"touchmove":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"header-line header-line__top"},[_c('div',{staticClass:"header__left"},[_c('router-link',{staticClass:"header__logo",attrs:{"to":{
            name: 'Home',
          },"tag":"div","div":""}},[_c('img',{attrs:{"alt":"logo","src":require("@/assets/pelmenya_logo.svg")}})]),(_vm.$store.state.windowWidth >= 1140)?[_c('receiveTypeSwitcher'),_c('HeaderAddress',{nativeOn:{"click":function($event){return _vm.chooseToggle()}}}),(
              _vm.$store.state.deliveryZone !== null &&
              _vm.$store.state.orderInfo.receiveType === 'delivery' &&
              _vm.$store.state.deliveryZone.deliveryZoneTypes.length === 1 &&
              _vm.$store.state.deliveryZone.deliveryZoneTypes[0] === 'frozen'
            )?_c('div',{staticClass:"header__address"},[_c('img',{staticClass:"header__address-image",attrs:{"src":require("@/assets/icons/snow.svg")}}),_vm._m(0)]):_vm._e()]:_vm._e()],2),(
          _vm.$store.state.windowWidth >= 768 && _vm.$store.state.windowWidth < 1140
        )?_c('div',{staticClass:"header__center"},[_c('receiveTypeSwitcher')],1):_vm._e(),_c('div',{staticClass:"header__right"},[(_vm.$store.state.userData === null)?_c('img',{staticClass:"logo__clickable",attrs:{"alt":"login","src":require("@/assets/icons/login.svg")},on:{"click":function($event){return _vm.$emit('toggleLayers', ['signIn'])}}}):_c('div',{staticClass:"header__account"},[_c('div',{staticClass:"header__account-balance"},[_vm._v(" "+_vm._s(_vm.$store.state.userData.accountBalance)+" ₽ ")]),_c('img',{staticClass:"logo__clickable",attrs:{"alt":"profile","src":require("@/assets/icons/profile.svg")},on:{"click":function($event){return _vm.$emit('toggleLayers', ['account'])}}})]),_c('div',{staticClass:"mobile-burger",attrs:{"id":"mobile-burger"},on:{"click":function($event){return _vm.$emit('toggleLayers', ['sidemenu'])}}},[_c('div',{staticClass:"bar1"}),_c('div',{staticClass:"bar2"}),_c('div',{staticClass:"bar3"})])])]),(
        _vm.$store.state.windowWidth >= 768 && _vm.$store.state.windowWidth < 1140
      )?_c('div',{staticClass:"header__hidden-lines header__hidden-lines--opened"},[_c('div',{staticClass:"header-line header-line--center"},[_c('HeaderAddress',{nativeOn:{"click":function($event){return _vm.chooseToggle()}}}),(
            _vm.$store.state.deliveryZone !== null &&
            _vm.$store.state.orderInfo.receiveType === 'delivery' &&
            _vm.$store.state.deliveryZone.deliveryZoneTypes.length === 1 &&
            _vm.$store.state.deliveryZone.deliveryZoneTypes[0] === 'frozen'
          )?_c('div',{staticClass:"header__address header__address--second"},[_c('img',{staticClass:"header__address-image",attrs:{"src":require("@/assets/icons/snow.svg")}}),_vm._m(1)]):_vm._e()],1)]):_vm._e(),(_vm.$store.state.windowWidth < 768)?_c('div',{staticClass:"header__hidden-lines",class:{
        'header__hidden-lines--opened': this.$store.state.headerOpened,
      }},[_c('div',{class:{
          'header-line': this.$store.state.headerOpened,
        }},[_c('receiveTypeSwitcher')],1),_c('div',{staticClass:"header-line--center",class:{
          'header-line': this.$store.state.headerOpened,
        }},[_c('HeaderAddress',{nativeOn:{"click":function($event){return _vm.chooseToggle()}}})],1),(
          _vm.$store.state.deliveryZone !== null &&
          _vm.$store.state.orderInfo.receiveType === 'delivery' &&
          _vm.$store.state.deliveryZone.deliveryZoneTypes.length === 1 &&
          _vm.$store.state.deliveryZone.deliveryZoneTypes[0] === 'frozen'
        )?_c('div',{staticClass:"header-line--center",class:{
          'header-line': this.$store.state.headerOpened,
        }},[_c('img',{staticClass:"header__address-image",attrs:{"src":require("@/assets/icons/snow.svg")}}),_vm._m(2)]):_vm._e()]):_vm._e(),(_vm.$store.state.cartCount > 0)?_c('div',{staticClass:"header-line header-line__cart"},[_c('div',{staticClass:"header__left"}),_c('div',{staticClass:"header__right header__full-line"},[(
            _vm.$store.state.orderInfo.receiveType === 'delivery' &&
            _vm.$store.state.deliveryZone !== null &&
            _vm.$store.state.cartPrice <
              _vm.$store.state.deliveryZone.deliveryMinTotalCost
          )?[_c('span',{staticClass:"header__text header__text--default-color"},[_vm._v("Минимальная сумма заказа: "),_c('span',{staticClass:"header__text-bold header__text--default-color"},[_vm._v(" "+_vm._s(_vm.$store.state.deliveryZone.deliveryMinTotalCost)+" ₽")])]),_c('span',{staticClass:"header__text"},[_vm._v("Необходимо добавить на сумму: "),_c('span',{staticClass:"header__text-bold"},[_vm._v(" "+_vm._s(_vm.$store.state.deliveryZone.deliveryMinTotalCost - _vm.$store.state.cartPrice)+" ₽")])])]:(
            _vm.$store.state.orderInfo.receiveType === 'delivery' &&
            _vm.$store.state.deliveryZone !== null &&
            _vm.$store.state.cartPrice <
              _vm.$store.state.deliveryZone.deliveryFreeFrom
          )?[_c('span',{staticClass:"header__text"},[_vm._v("Доставка: "),_c('span',{staticClass:"header__text-bold"},[_vm._v(" "+_vm._s(_vm.$store.state.deliveryZone.deliveryCost)+" ₽")])]),_c('span',{staticClass:"header__text"},[_vm._v("Итого, включая доставку: "),_c('span',{staticClass:"header__text-bold"},[_vm._v(" "+_vm._s(_vm.$store.state.cartPrice + _vm.$store.state.deliveryZone.deliveryCost)+" ₽")])])]:[_c('span',{staticClass:"header__text"},[_vm._v("Итого: "),_c('span',{staticClass:"header__text-bold"},[_vm._v(" "+_vm._s(_vm.$store.state.cartPrice)+" ₽")])])],_c('div',{staticClass:"header__cart-wrapper",on:{"click":_vm.openCart}},[_c('div',{staticClass:"header__cart-counter"},[_vm._v(" "+_vm._s(_vm.$store.state.cartCount)+" ")]),_c('img',{attrs:{"alt":"cart","src":require("@/assets/icons/cart.svg")}})])],2)]):_vm._e()]),(_vm.$store.state.windowWidth < 768 && _vm.zoneExist)?_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.bottom",value:(
      function () {
        _vm.swipeHeader(true);
      }
    ),expression:"\n      () => {\n        swipeHeader(true);\n      }\n    ",arg:"swipe",modifiers:{"bottom":true}},{name:"touch",rawName:"v-touch:swipe.top",value:(
      function () {
        _vm.swipeHeader(false);
      }
    ),expression:"\n      () => {\n        swipeHeader(false);\n      }\n    ",arg:"swipe",modifiers:{"top":true}},{name:"touch-options",rawName:"v-touch-options",value:({ touchClass: 'active', swipeTolerance: 30 }),expression:"{ touchClass: 'active', swipeTolerance: 30 }"}],staticClass:"header-toggler",on:{"click":_vm.toggleHeader}},[_c('img',{ref:"header-toggler",staticClass:"header-toggler__image",attrs:{"src":require("@/assets/icons/header-toggler.svg")}})]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"header__address-text"},[_vm._v("По вашему адресу - доставка "),_c('br'),_vm._v(" только замороженных п/ф")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"header__address-text"},[_vm._v("По вашему адресу - доставка "),_c('br'),_vm._v(" только замороженных п/ф")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"header__address-text"},[_vm._v("По вашему адресу - доставка "),_c('br'),_vm._v(" только замороженных п/ф")])}]

export { render, staticRenderFns }