var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"receive-type-switcher"},_vm._l((_vm.$store.state.receiveTypes),function(type,i){return _c('div',{key:type.id,class:{
      'receive-type-switcher__left': i === 0,
      'receive-type-switcher__right':
        i === _vm.$store.state.receiveTypes.length - 1,
      'receive-type-switcher__middle':
        i > 0 && i < _vm.$store.state.receiveTypes.length - 1,
      'receive-type-switcher__active':
        type.id === _vm.$store.state.orderInfo.receiveType,
    },on:{"click":function($event){_vm.$store.commit('changeReceiveType', type.id);
      _vm.$store.commit('changePaymentType', 'online');}}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }