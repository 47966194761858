<template>
  <div class="header__address">
    <template v-if="$store.state.orderInfo.receiveType !== 'delivery'">
      <img class="header__address-image" src="@/assets/icons/cook.svg" />
      <span v-if="choosenRestaurant !== null" class="header__address-text">{{
        choosenRestaurant.address
      }}</span>
      <span v-else class="header__address-text">Выберите ресторан</span>
      <img
        class="header__address-image"
        src="@/assets/icons/arrow-right-pl-yellow.svg"
      />
    </template>
    <template v-else>
      <img class="header__address-image" src="@/assets/icons/moto.svg" />

      <span v-if="deliveryAddress !== null" class="header__address-text"
        >{{ deliveryAddress.street }}, {{ deliveryAddress.house }}</span
      >
      <span v-else class="header__address-text"
        >Выберите адрес для доставки</span
      >
      <img
        class="header__address-image"
        src="@/assets/icons/arrow-right-pl-yellow.svg"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "HeaderAddress",
  data() {
    return {
      deliveryAddress: this.$store.state.deliveryAddress,
      choosenRestaurant: this.$store.state.choosenRestaurant,
    };
  },
  watch: {
    "$store.state.choosenRestaurant"() {
      this.choosenRestaurant = this.$store.state.choosenRestaurant;
    },
    "$store.state.deliveryAddress"() {
      this.deliveryAddress = this.$store.state.deliveryAddress;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.header__address-text {
  margin: 0 16px;
  font-size: 12px;
  line-height: 16px;
  color: $pl-yellow;
}
</style>
